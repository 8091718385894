@import '../../abstracts/styles/config';

.Footer {
  max-height: 300px;

  .footer-2box {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    height: 100%;

    h4 {
      text-transform: uppercase;
      font-weight: 700;
    }

    &-top {
      background-color: $primary-color;
      border-top: 2px solid rgba(255, 205, 94, 0.7);
      height: 100%;
      width: 100%;
      display: flex;
      padding: 10px;
      justify-content: space-around;

      a {
        color: $dark-color;
        font-size: 0.9rem;
      }

      &__menu {
        display: flex;
        flex-direction: column;
        height: 100%;
      }

      &__myaccount {
        height: 100%;
        display: flex;
        flex-direction: column;
      }

      &__contact {
        display: flex;
        flex-direction: column;
        height: 100%;
      }
    }

    &-bottom {
      background-color: $dark-color;
      height: 100%;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      align-items: center;

      &__social {
        .social-icons__list {
          a {
            color: rgba(255, 255, 255, 0.9);
            padding: 0 0.45rem;
            margin: 0 0.25rem;
          }

          a:hover,
          a:active {
            color: $primary-color;
          }
        }
      }

      &__logo {
        .Logo {
          margin: 1rem 0;
          margin-right: 0;
          font-size: 1.2rem;
        }
      }

      &__copyright {
        text-align: center;
        color: #626a73;
        margin-top: 10px;
        padding-bottom: 5px;

        .CopyRight {
          display: inline-block;
        }
      }
    }
  }
}

@include screen(mobile) {
  .footer-2box-top {
    .footer-2box-top__myaccount {
      display: none;
    }

    &__contact {
      span,
      div {
        font-size: 0.9rem;
      }
    }
  }
}
