@import '../../abstracts/styles/_config.scss';
// Buttons
.btn {
  display: inline-block;
  background: $light-color;
  color: #333;
  padding: 0.6rem 1.3rem;
  border: none;
  cursor: pointer;
  font-size: 1rem;
  margin-right: 0.5rem;
  margin-left: 0.5rem;
  outline: none;
  transition: all 0.2s ease-in;
  margin-top: 10px;
  border-radius: 1px;

  &.btn-primary {
    @include set-background($primary-color);

    &:hover {
      background: lighten($primary-color, 5%);
    }
  }

  &.btn-dark {
    @include set-background($dark-color);
    &:hover {
      background: lighten($dark-color, 5%);
    }
  }

  &.btn-success {
    @include set-background($success-color);
    &:hover {
      background: lighten($success-color, 5%);
    }
  }

  &.btn-danger {
    @include set-background($danger-color);
    &:hover {
      background: lighten($danger-color, 5%);
    }
  }

  &.btn-transparent {
    background: transparent;
    color: $light-color;
    border: 1px solid $light-color;

    &:hover {
      background: $primary-color;
      color: $dark-color;
      border: none;
    }
  }

  &:hover {
    background: lighten($dark-color, 20%);
    color: #ffffff;
  }
}
