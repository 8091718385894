.Custom404 {
  margin-top: 4rem;

  .custom404_landing {
    margin-top: 4rem;
    max-height: 90vh;

    &_content__header {
      margin: 1.5rem 0;
      text-align: center;
    }
    &_content__lead {
      padding: 0.1rem 0;
    }

    &_content_footer {
      margin-top: 1rem;
    }

    &_image {
      background: url('../../assets/img/custom404-image.jpg') no-repeat center
        center/cover;
      height: 50vh;
      margin-top: 1.2rem;
    }
  }
}
