@import '../../abstracts/styles/config';
@import '../../abstracts/styles/utility';

.Navbar {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0.7rem 2rem;
  position: fixed;
  z-index: 199;
  width: 100%;
  top: 0;
  border-bottom: solid 1px $primary-color;
  font-family: 'Playfair Display', serif;
  background: transparent;

  &.navbar-sm {
    background: $dark-color;
    opacity: 0.97;
  }

  .navbar__links {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    margin: 0.4rem 0;

    li {
      &:hover {
        transform: translateY(-2px);
      }
    }

    // specific styling for dropdown
    .navbar-dropdown-container {
      position: relative;
      display: inline-block;

      .dropdown-button {
        margin: 0;
      }

      .navbar-dropdown {
        position: absolute;
        top: 100%;
        left: 0;
        width: 200px;
        z-index: 2;
        border: 1px solid rgba(0, 0, 0, 0.4);
        border-radius: 5px;
        box-shadow: 0 16px 24px 2 px rgba(0, 0, 0, 0.14);
        background-color: white;

        ul {
          li {
            padding: 8px 12px;

            .dropdown-link {
              color: $dark-color;
              margin: 0;
              padding: 0;
              letter-spacing: 0.1rem;
            }
          }

          li:hover {
            background-color: rgba(0, 0, 0, 0.14);
            cursor: pointer;
          }
        }
      }
    }

    .navbar__link {
      margin: 1rem;
      cursor: pointer;
      text-decoration: none;
      letter-spacing: 0.3rem;
      text-indent: 0.3rem;
      color: $light-color-semi-transparent;
      border-bottom: 2px solid transparent;

      &--active {
        border-bottom: 1px solid $light-color-semi-transparent;
        padding-bottom: 5px;
        transition: border-bottom 0.5s ease-in-out;
      }
    }
  }

  .navbar-top__links {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: dashed 1px rgba($color: #ccc, $alpha: 0.2);
    margin: 1rem 0;
    padding-bottom: 1rem;

    .navbar-top__icons {
      a:hover,
      a:active {
        color: $primary-color;
      }
    }

    &.navbar-sm {
      display: none;
    }
  }

  a {
    color: $light-color-semi-transparent;
    padding: 0 0.45rem;
    margin: 0 0.25rem;
  }
}

@include screen(mobile) {
  .Navbar {
    height: 12%;
    max-height: 60px;
    .navbar__links {
      display: none;
    }

    .navbar-top__links {
      border-bottom: none;

      &.navbar-sm {
        display: flex;
      }

      .navbar-top__logo {
        display: none;
      }
    }
  }
}

@include screen(tablet) {
  .Navbar {
    height: 10%;
    max-height: 80px;
    .navbar__links {
      display: none;
    }

    .navbar-top__links {
      border-bottom: none;

      &.navbar-sm {
        display: flex;
      }

      .navbar-top__logo {
        display: none;
      }
    }
  }
}

@include screen(desktop) {
  .navbar-top__menu {
    .DrawerToggleButton {
      display: none;
    }
  }
}
