.PrivacyPage {
  margin: 1rem 0 1rem 0;

  &-section {
    &__header {
      text-align: center;
      margin: 1rem 0 1rem 0;
    }

    &__sub-header {
      margin: 1rem 0 1rem 0;
    }
  }
}
