@import './_config';

// Text Styles
.x-large {
  font-size: 3rem;
  line-height: 1.2;
  magin-bottom: 1rem;
}
.large {
  font-size: 3rem;
  line-height: 1.2;
  magin-bottom: 1rem;
}

.lead {
  font-size: 1.2rem;
  magin-bottom: 1rem;
}

//Backgrounds
.bg {
  &-primary {
    @include set-background($primary-color);
  }
  &-light {
    @include set-background($light-color);
    border: #ccc 1px solid;
  }
  &-dark {
    @include set-background($dark-color);
  }
  &-success {
    @include set-background($success-color);
  }
  &-danger {
    @include set-background($danger-color);
  }
  &-white {
    @include set-background(#fff);
    border: #ccc 1px solid;
  }
}

//Allows a dark overlay over images
.dark-overlay {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba($color: #000000, $alpha: 0.5);
}

// Hides an element
.hide {
  display: none;
}

.divider {
  padding: 1px;
  background-color: black;
  margin: 0 auto;
  max-width: 10%;
}
