@import '../../abstracts/styles/config';
.card-two-box {
  .card-container {
    display: flex;
    flex-direction: column;

    .card-wrap {
      .card {
        box-shadow: 0 0 4px rgba(0, 0, 0, 0.4);
      }

      .card-content {
        display: flex;
        flex-direction: column;

        &-inner-container {
          display: flex;
          flex-direction: column;
          padding: 0 0.5rem 2rem 0.5rem;
          justify-content: space-evenly;
          overflow: hidden;
          margin: 1rem;

          .btn {
            max-width: 150px;
            margin: 0 auto;
          }
        }

        &.content__dark {
          background: $dark-color;
          color: $light-color;
        }

        .card-content-title__wrapper {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          height: 15%;
        }

        .card-title__break {
          width: 40px;
          height: 2px;
          background: $dark-color;
          margin: 1rem 0 1rem 0;
        }

        .card-content__lead {
          flex-grow: 1;
          min-height: 0;
          overflow: auto;
          margin-bottom: 1rem;
          padding-bottom: 20px;
        }
      }
      .card-image-container {
        height: 300px;

        .card-image {
          background: url('https://storage-directus.s3.amazonaws.com/storage/14d203e1-92f1-48c8-8390-73e79f697876.jpg')
            no-repeat center center/cover;
          padding: 1rem;
          height: 100%;
        }

        .card-image-2 {
          background: url('https://storage-directus.s3.amazonaws.com/storage/fa3e206c-b6e5-4ba0-9d37-6d5166bda2a0.jpg')
            no-repeat center center/cover;
          height: 100%;
          padding: 1rem;
        }
      }
    }
  }
}

@include screen(desktop) {
  .card-two-box {
    .card-container {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap-reverse;
      height: 100%;
      width: 100%;

      .card-wrap {
        flex: 0 0 50%;

        .card {
          flex: 0 0 100%;
          box-shadow: 0 0 4px rgba(0, 0, 0, 0.4);
        }

        .card-image-container {
          height: 100%;
        }
      }
    }
  }
}
@include screen(tablet) {
  .card-two-box {
    .card-container {
      display: flex;
      flex-direction: row;
      height: 70vh;
      flex-wrap: wrap-reverse;
      height: 100%;
      width: 100%;

      .card-wrap {
        flex: 0 0 50%;

        .card {
          flex: 0 0 100%;
          box-shadow: 0 0 4px rgba(0, 0, 0, 0.4);
        }

        .card-image-container {
          height: 100%;
        }
      }
    }
  }
}
