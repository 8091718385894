.session-header {
  text-align: center;

  &__lead {
    font-size: 2rem;
    text-transform: uppercase;
    margin: 1.5rem 0;
  }
  .break {
    padding: 1px;
    background-color: black;
    margin: 0 auto;
    max-width: 10%;
  }
  &-content__container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 1rem;
    margin-bottom: 2rem;
    font-size: 0.9rem;

    .session-header-content {
      padding: 10px;
      font-weight: 200;

      &__status {
        padding: 5px;
        font-weight: 400;
        font-family: sans-serif;
      }

      &__date {
        padding: 5px;
        font-weight: 400;
        font-family: sans-serif;
      }
    }
  }
}

.no-session-header {
  .no-session-header-content {
    margin-top: 10%;

    .no-session-header__lead {
      text-align: center;
    }
  }
  &-content__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;

    .no-session-header__image-wrapper {
      .no-session-header__image {
        width: 100%;
        height: auto;
      }
    }
  }
}
