@import '../../abstracts/styles/utility';
@import '../../abstracts/styles/config';

.contact-cta__container {
  position: relative;
  height: 100vh;
  width: 100vw;

  .contact-cta__bgimage {
    width: 100%;
    height: 100%;
    background: url('https://storage-directus.s3.amazonaws.com/storage/64b83d4a-1eeb-4a20-909f-4b91f1b3b0bf.jpg')
      no-repeat center center/cover;
  }

  .contact-cta__card {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    outline: 2px solid $dark-color;
    outline-offset: -10px;
    transition: 0.3s;
    padding: 20px;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-left: -175px;
    margin-top: -175px;
    background: white;
    height: 350px;
    width: 350px;
    z-index: 20;

    &:hover {
      box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
    }

    .contact-cta-card__lead {
      text-align: center;
      margin-bottom: 20px;
    }

    .contact-cta-card__content {
      margin-top: 10px;
      font-size: 0.9rem;
    }

    .btn-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-top: 30px;
      .btn {
        margin: 0 auto;
        max-width: 150px;
      }
    }
  }
}

@include screen(desktop) {
  .contact-cta__container {
    .contact-cta__card {
      height: 400px;
      width: 500px;
      margin-left: -250px;
      margin-top: -200px;

      .contact-cta-card__lead {
        margin-bottom: 40px;
      }

      .contact-cta-card__content {
        margin-top: 30px;
      }

      .btn-container {
        margin-top: 50px;
      }
    }
  }
}
