@import '../../abstracts/styles/config';

.SideDrawer {
  height: 100%;
  background: white;
  box-shadow: 4px 0px 7px rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  width: 70%;
  max-width: 400px;
  z-index: 200;
  transform: translateX(-100%);
  transition: transform 0.3s ease-out;

  &.open {
    transform: translateX(0);
  }

  .SideDrawer__links {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-top: 1rem;
    align-items: center;

    li {
      padding: 20px;

      &:hover {
        transform: translateY(-2px);
      }
    }

    .navbar-dropdown {
      li {
        padding: 10px 0 10px 20px;
      }

      li:hover {
        background-color: rgba(0, 0, 0, 0.1);
      }
    }

    .SideDrawer__link {
      cursor: pointer;
      text-decoration: none;
      letter-spacing: 0.3em;
      text-indent: 0.3em;
      color: $dark-color;
      border-bottom: 2px solid transparent;

      &--active {
        border-bottom: 1px solid $primary-color;
        padding-bottom: 5px;
        transition: border-bottom 0.5s ease-in-out;
      }
    }
  }
}
