@import '../../abstracts/styles/utility';

.session-container {
  margin-top: 3rem;
  .session-info {
    display: flex;
    width: 100%;
    border: 1px solid rgb(101, 99, 99);
    border-radius: 5px;
    padding: 1.5rem;

    .session-date {
      padding-right: 10px;
    }
  }

  .photo-gallery {
    margin-top: 1rem;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    grid-gap: 20px;
    align-items: stretch;

    &-finished-head {
      text-align: center;
      &__header {
        font-size: 2rem;
        text-transform: uppercase;
        margin: 3rem 0 1.3rem 0;
      }

      &__lead {
        margin-bottom: 4rem;
      }
    }

    .photo-gallery__item {
      height: auto;
      width: 100%;
      position: relative;
      overflow: hidden;

      .photo-gallery__content {
        position: relative;
        overflow: hidden;
        width: 100%;

        &:hover {
          //On raw_photo hover show the view and request buttons
          .photo-gallery__icon-wrapper {
            opacity: 1;
            display: block;
          }

          .session-photo {
            transform: scale(1.5);
          }
        }

        .session-photo {
          border: 1px solid #ccc;
          box-shadow: 2px 2px 6px 0px rgba(0, 0, 0, 0.3);
          max-width: 100%;
          height: auto !important;
          position: relative;
          display: block;
          transition: all 2s ease-in-out;
        }

        .loading {
          color: white;
          position: absolute;
          display: none;
          bottom: 5px;
          rigth: 10px;
        }

        .on-request {
          display: none;
        }

        .on-request.active {
          position: absolute;
          top: 0;
          right: 0;
          left: auto;
          width: 110px;
          text-align: center;
          color: #fff;
          display: block;
          margin: -40px -48px 0 0;
          padding: 60px 0 4px;
          font-size: 11px;
          font-weight: 600;
          text-transform: uppercase;
          z-index: 9;
          -webkit-transform: rotate(45deg);
          -moz-transform: rotate(45deg);
          -ms-transform: rotate(45deg);
          -o-transform: rotate(45deg);
          transform: rotate(45deg);
          border-radius: 0;
          min-height: 0;
          line-height: 30px;
          background: #2bb213;
        }

        .photo-id {
          position: absolute;
          top: 0;
          left: 0;
          color: white;
          background: rgba(0, 0, 0, 0.5);
          padding: 5px 10px 2px 10px;
        }

        .photo-gallery__icon-wrapper {
          background: rgba(0, 0, 0, 0.5);
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          display: none;
          opacity: 0;
          z-index: 3;
          transition: 1s;
          overflow: hidden;

          .photo-gallery__icon-content {
            display: table;
            width: 100%;
            height: 100%;
            text-align: center;

            .photo-gallery__icon-content-middle {
              display: table-cell;
              color: white;
              text-align: center;
              vertical-align: middle;

              .tooltip {
                margin: 0 8px 0 8px;
                color: white;
                position: relative;
                display: inline-block;

                .caption {
                  width: 120px;
                  background-color: rgba(0, 0, 0, 0.5);
                  border-radius: 5px;
                  color: white;
                  text-align: center;
                  padding: 5px 0;
                  border: 6px;
                  position: absolute;
                  bottom: 100%;
                  left: 50%;
                  margin-left: -60px;
                  z-index: 4;
                }

                .caption::after {
                  content: ' ';
                  position: absolute;
                  top: 100%;
                  left: 50%;
                  margin-left: 5px;
                  border-width: 5px;
                  border-style: solid;
                  border-color: rgba(0, 0, 0, 0.5) transparent transparent
                    transparent;
                }
              }
              .tooltip:hover {
                .caption {
                  visibility: visible;
                }
              }

              .caption {
                visibility: hidden;
              }

              .request {
                display: inline-block;
              }

              .hidden {
                display: none;
              }
            }
          }
        }
      }
    }
  }
}
