@import '../../abstracts/styles/config';

.Landing {
  margin: 0 auto;
  width: 100%;

  &-content {
    &__block {
      margin: 4rem 1rem 4rem 1rem;
    }

    &__header {
      text-align: center;
      padding-bottom: 20px;
    }

    &__lead {
      padding-bottom: 3rem;
    }

    &__dropdown {
      max-width: 800px;
      margin: 0 auto;
      margin-bottom: 7rem;
    }
  }
}
