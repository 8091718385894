@import url('https://fonts.googleapis.com/css?family=Playfair+Display|Raleway&display=swap');
@import '../../abstracts/styles/config';
@import 'react-redux-toastr/src/styles/index';

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  height: 100%;
}

body {
  font-family: 'Raleway', sans-serif;
  font-size: 1rem;
  line-height: 1.6;
  height: 100%;
}

a {
  text-decoration: none;
}

ul {
  list-style: none;
}
