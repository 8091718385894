@import '../../abstracts/styles/config';

.register-form-wrapper {
  height: auto;
  padding: 2rem 1.8rem;
  width: 90%;
  max-width: 600px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 2px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  background-color: $light-color;
  z-index: 1;

  a {
    &:visited {
      color: $dark-color;
    }
  }

  .form-top {
    text-align: center;
    margin-bottom: 1rem;
    &__header {
      padding-bottom: 20px;
      color: $dark-color;
    }

    &__content {
      color: rgb(138, 138, 138);
    }
  }

  .form-bottom {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    width: 100%;

    .primary-button {
      border-radius: 10px;
      width: 30%;
      margin-bottom: 10px;
    }

    .seperator {
      margin-top: 1.3rem;
      width: 100%;
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      &-line {
        background-color: $dark-color;
        height: 1px;
        width: 33%;
        display: inline-block;
      }
    }

    .form-bottom-cta {
      margin-top: 1.5rem;
      display: flex;
      &__content {
        padding: 0 5px;
      }
    }
  }

  .Form {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    overflow: hidden;

    .form-name-section {
      position: relative;
      width: 100%;
      height: 100%;
      margin-top: 2rem;

      .form-input {
        width: 100%;
        height: 100%;
        border: none;
        outline: none;
        padding-top: 1.4rem;
        background-color: $light-color;
        font-size: 0.9rem;
        font-family: 'Raleway', sans-serif;

        &:focus + .form-label-name .content-name,
        &:not(:placeholder-shown) + .form-label-name .content-name {
          transform: translateY(-150%);
          font-size: 1rem;
        }

        &:focus + .form-label-name,
        &:not(:placeholder-shown) + .form-label-name {
          &::after {
            transform: translateX(0%);
          }
        }
      }

      .form-label-name {
        position: absolute;
        left: 0%;
        bottom: 0;
        font-size: 0.8rem;
        height: 100%;
        width: 100%;
        pointer-events: none;
        border-bottom: 1px solid $dark-color;
        font-family: 'Raleway', sans-serif;

        &:after {
          content: '';
          position: absolute;
          height: 100%;
          width: 100%;
          left: 0px;
          bottom: -1px;
          border-bottom: 1.5px solid $primary-color;
          transform: translateX(-100%);
          transition: all 0.3s ease;
        }

        &.error {
          &:after {
            content: '';
            position: absolute;
            height: 100%;
            width: 100%;
            left: 0px;
            bottom: -1px;
            border-bottom: 1.5px solid $danger-color;
            transform: translateX(0%);
            transition: all 0.3s ease;
          }
        }

        .content-name {
          position: absolute;
          bottom: 5px;
          left: 0px;
          transition: all 0.3s ease;

          &.error {
            color: $danger-color;
          }
        }
      }
    }
    .forgot-password {
      margin: 1rem 0;
      display: flex;
      align-self: flex-end;
      font-family: 'Raleway', sans-serif;
      &__text {
      }
    }
  }
}
