.Login {
  .login-page-background {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background: url('../../assets/bg-image.jpg') no-repeat center center/cover;
    height: 100vh;
    width: 100vw;
    filter: blur(5px);
    -webkit-filter: blur(5px);
    -moz-filter: blur(5px);
    -o-filter: blur(5px);
    -ms-filter: blur(5px);
    display: block;
  }
}
