@import '../../abstracts/styles/config';

.three-box {
  margin: 1rem;
  padding: 10px 8px;
  display: flex;
  flex-direction: column;

  &-container__content {
    padding: 20px 0;
    .three-box__lead {
      padding: 20px 0;
      font-size: 1.5rem;
    }

    .three-box__divider {
      padding: 1px;
      background-color: black;
      margin: 0 auto;
      max-width: 10%;
    }

    .three-box__content {
      padding: 30px 20px;
      font-size: 0.9rem;
      justify-content: center;
      align-items: center;
    }
  }
  &-container__box {
    .three-box-image__container {
      padding: 20px 0;
      display: block;
      .three-box-box__image {
        width: 100%;
        height: auto;
      }
    }

    .three-box-box-container__content {
      .three-box-box__lead {
        font-weight: 800;
        padding: 10px 0;
        font-size: 1.2rem;
      }

      .three-box-box__content {
        font-size: 0.9rem;
        padding-bottom: 20px;
      }
    }
  }
}

@include screen(desktop) {
  .three-box-container__content {
    text-align: center;
  }

  .three-box-container__box {
    display: flex;
    align-items: center;
    justify-content: space-evenly;

    .three-box-box {
      padding: 0 15px;
    }
  }
}
