@import '../../abstracts/styles/config';

.Logo {
  display: flex;
  align-items: center;
  margin-right: 100px;
  color: $light-color-semi-transparent;
}

.logo-icon {
  margin-right: 5px;
  padding: 0.1rem;
}
