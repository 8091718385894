@import '../../abstracts/styles/_config.scss';

.DrawerToggleButton {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 1.6rem;
  background: transparent;
  width: 2rem;
  border: none;
  cursor: pointer;

  &:focus {
    outline: none;
  }

  .DrawerToggleButton__line {
    width: 30px;
    height: 2px;
    background: $light-color-semi-transparent;
  }
}
