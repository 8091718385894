.Register {
  .register-page-background {
    position: fixed;
    left: 0;
    right: 0;
    background: url('../../assets/bg-image.jpg') no-repeat center center/cover;
    height: 100vh;
    filter: blur(5px);
    // filter: blur(5px);
    // -webkit-filter: blur(5px);
    // -moz-filter: blur(5px);
    // -o-filter: blur(5px);
    // -ms-filter: blur(5px);
    display: block;
  }
}
