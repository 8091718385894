.site {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  justify-content: space-between;

  &-content {
    flex: 1;
    width: 100%;
  }
}
