//Colors
$primary-color: #f7ad0d;
$dark-color: #343a40;
$light-color: #f4f4f4;
$light-color-semi-transparent: rgba(255, 255, 255, 0.9);

// Utility Colors
$danger-color: #dc3545;
$success-color: #28a745;

// Layout
$max-width: 1100px;

// Set text color based on background
@function set-text-color($color) {
  @if (lightness($color) > 50) {
    @return #222;
  } @else {
    @return #f4f4f4;
  }
}

// Set background and text color
@mixin set-background($color) {
  background-color: $color;
  color: set-text-color($color);
}

@mixin screen($size) {
  $desktop: '(min-width: 1024px)';
  $tablet: '(min-width: 768px) and (max-width: 1023px)';
  $mobile: '(max-width: 767px)';
  @if $size == desktop {
    @media only screen and #{$desktop} {
      @content;
    }
  } @else if $size == tablet {
    @media only screen and #{$tablet} {
      @content;
    }
  } @else if $size == mobile {
    @media only screen and #{$mobile} {
      @content;
    }
  } @else {
    @media only screen and #{$size} {
      @content;
    }
  }
}
