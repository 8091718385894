.ScrollIcon {
  width: 20px;
  height: 35px;
  margin-left: -10px;
  position: absolute;
  bottom: 20px;
  margin-top: -35px;
  box-shadow: inset 0 0 0 3px #ffffff;
  border-radius: 25px;
  z-index: 99;
  left: 50%;

  &::before {
    content: '';
    width: 4px;
    height: 4px;
    background: #fff;
    margin-left: -2px;
    top: 4px;
    border-radius: 4px;
    animation-duration: 1.5s;
    animation-iteration-count: infinite;
    animation-name: scroll;
    position: absolute;
    left: 50%;
  }
}

@keyframes scroll {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: translateY(23px);
  }
}
