@import '../../abstracts/styles/config';

.landing {
  position: relative;
  background: url('https://storage-directus.s3.amazonaws.com/storage/39286827-f943-49e9-a533-1286184e8ec5.jpg')
    no-repeat center center/cover;
  height: 100vh;

  &-inner {
    color: #fff;
    height: 100%;
    display: flex;
    flex-direction: column;
    width: 80%;
    margin: auto;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
}

@include screen(mobile) {
  .HomePage {
    .landing-inner {
      .large {
        font-size: 2rem;
      }

      .lead {
        font-size: 1rem;
      }

      .btn {
        padding: 0.5rem 1rem;
      }
    }
  }
}
